import React from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import { Box, Flex, Themed } from "theme-ui"

const Header = () => {

  const data = useStaticQuery(
    graphql`
      query Header {
        dataJson(slug: {eq: "header"}) {
          fragment_1
          fragment_2
          fragment_3
        }
      }
    `
  )

  const dataFragments = data.dataJson

  return(
    <Box
      as={`header`}
      sx={{
        backgroundColor: `gray.2`,
        px: 3,
        ".nav": {
          alignItems: `center`,
          height: `100%`,
          justifyContent: `space-between`
        },
        a: {
          color: `black`,
          fontSize: [1, 2],
          borderBottom: `none`,
          ":hover": {
            color: `black`,
            borderBottom: theme => `1px solid ${theme.colors.black}`,
          }
        },
        ".link-to-lang": {
          fontSize: 0,
          textTransform: `uppercase`
        }
      }}
    >
      <Flex as="div" className="nav">
        <Themed.a as={Link} to="/" className="nav-link link-to-home">{dataFragments.fragment_1}</Themed.a>
      </Flex>
    </Box>
  )

}

export default Header