import React from "react"
import Header from "./header"
import Footer from "./footer"
import { Box, Grid, Themed } from "theme-ui"

const Layout = ( props ) => {

  return (
    <Themed.root>
      <Grid
        as={`div`}
        gap={0}
        sx={{
          gridTemplateColumns: `1fr`,
          gridTemplateRows: [ `3rem auto auto`,
                              `3rem auto auto`,
                              `4.5rem auto auto`,
                              `4.5rem 1fr auto`,
                              `4.5rem 1fr auto`,
                              `4.5rem 1fr auto`
                            ],
          gridTemplateAreas:[`"header" "main" "footer"`],
          header:{
            gridArea: `header`,
          },
          main:{
            gridArea: `main`,
            py: 4,
            paddingLeft: 3,
            maxWidth: [`phoneMaxWidht`, `tabletMaxWidth`, `tabletMaxWidth`, `desktopMaxWidth`, `desktopMaxWidth`, `desktopLargeMaxWidth`],
          },
          footer:{
            gridArea: `footer`,
          }
        }}
      >
        <Header />
        <Box as={`main`} className="main-content">
          {props.children}
        </Box>
        <Footer />
      </Grid>
    </Themed.root>
  )

}

export default Layout
